import { CopyToClipboard } from 'react-copy-to-clipboard';
import Header from '../components/Header';
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import Notification from '../components/Notification';
import Botmenu from '../components/Botmenu';
import MobileHeader from '../components/MobileHeader';

const ReferralCode = () => {
    const navigate = useNavigate();
    const [totalReward, setTotalReward] = useState(0);
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [userData, setUserData] = useState(null);
    const [inviteLink, setInviteLink] = useState('');
    const [inviteTelegram, setInviteTelegram] = useState('');
    const [parentsCount, setParentsCount] = useState(0); 
    const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/myteam', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    const refferalData = response.data;
                    setRefferal(refferalData);

                })

        }
    }, [token]);
    
    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        setInviteTelegram(`https://telegram.me/share/url?url=https://luckyx.cloud/sign-up?referral=${userData.referral}&text=به ما بپیوندید!`);
                        setInviteLink(`https://luckyx.cloud/sign-up?referral=${userData.referral}`);
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                        setTotalReward(response.data.user.reward);
                        
                        const parents1Count = userData.parent ? userData.parent.length : 0;
                        const parents2Count = userData.parent2 ? userData.parent2.length : 0;
                        setParentsCount(parents1Count + parents2Count); 
                    } else {
                        handleTokenChanged();
                    }
                })
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const handleShareTelegram = () => {
        window.open(inviteTelegram, '_blank');
    }

    const handleShare = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setNotificationMessage('لینک شما کپی شد!');
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            })
    };

    const AllRef = refferal.parents1.length  + refferal.parents2.length

    return (
        <>
            <Notification message={ notificationMessage} open={showNotification} />

            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="referral-code mt-3">
                        <div className="columns res">
                            <div className="column head_info">
                                <h2 className='has-text-centered'>لینک دعوت</h2>
                            </div>
                            <div className="column telegram_ref_code">
                                <p>دعوت از تلگرام</p>
                                <p className='mt-4'>با دعوت از طریق تلگرام سود بیشتری دریافت خواهید کرد</p>
                                <button onClick={handleShareTelegram}><TelegramIcon className='iconam' />اشتراک گذاری در تلگرام</button>
                            </div>
                            <div className="column mt-3 site_ref_code">
                                <p>دعوت از سایت</p>
                                <p className='mt-4 has-text-left'>{inviteLink}</p>
                                <button onClick={handleShare}><LinkIcon className='iconam' />کپی لینک </button>
                            </div>
                            <div className="column ref_info">
                                <div className="columns is-flex is-justify-content-space-between">
                                    <div className="column">
                                        <button>لینک های من</button>
                                    </div>
                                    <div className="column">
                                        <button>گزارش ها</button>
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column">
                                        <p>کل سود</p>
                                    </div>
                                    <div className="column has-text-left">
                                        <p>{totalReward > 0 ? (<>{totalReward}</>) : (<>0</>)} trx</p>
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column">
                                        <p>ثبت شد</p>
                                    </div>
                                    <div className="column has-text-left">
                                        <p>{AllRef > 0 ? (<>{AllRef}</>) : (<>0</>)} </p>
                                    </div>
                                </div>
                                <div className="columns is-flex">
                                    <div className="column">
                                        <p>دوستان فعال</p>
                                    </div>
                                    <div className="column has-text-left">
                                        <p>0 </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    );
};

export default ReferralCode;