import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import { Formik, Form, Field, ErrorMessage } from 'formik'; // Import Formik components

export default function BuyToken() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    // Token price
    const [tokenBuy, setTokenBuy] = useState({});

    useEffect(() => {
        if (userData.token) {
            axios.get('https://luckyx.cloud/api/v2/user/tokenprice', {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                }
            })
                .then(response => {
                    setTokenBuy(response.data.tokenprice);
                });
        }
    }, [userData.token]);

    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="container lotery_join_page mt-3">
                        <div className="columns join_lot">
                            <div className="column is-5">
                                <h2 className="title has-text-centered">خرید توکن LX</h2>
                                <p style={{ textAlign: 'justify' }}>لاکی ایکس برای عرضه اولیه توکن هارا به صورت 50000 واحدی پک بندی کرده است و مبلغ هر پک از 100 ترون با دراختیار کاربران قرار داده می شود و شما هیچ محدودیتی در اضافه خریدن این ارز ندارید.</p>
                                <Formik
                                    initialValues={{ wallet: userData.wallet || '', tokens: '' }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.tokens) {
                                            errors.tokens = 'تعداد پک را وارد کنید';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);

                                        // Check if wallet is valid
                                        if (userData.wallet === "unknown" || userData.wallet === null) {
                                            setNotificationMessage('لطفا ولت خود را از قسمت تایید آدرس پرداشت تایید کنید ');
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                                navigate('/Verify', { replace: true });
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        // Check if user name is valid
                                        if (userData.name === "unknown" || userData.name === null ) {
                                            setNotificationMessage('لطفا نام کاربری خود را از قسمت پروفایل ویرایش کنید.');
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                                navigate('/setting', { replace: true });
                                            }, 3000);
                                            setSubmitting(false);
                                            return;
                                        }

                                        const formData = new FormData();
                                        formData.append('count', parseInt(values.tokens)); // Use 'tokens' instead of 'tickets'
                                        formData.append('wallet', userData.wallet); // Use userData.wallet directly

                                        

                                        const token = userData.token; // Get token from userData
                                        axios.post('https://luckyx.cloud/api/v2/user/buytoken', formData, {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        })
                                            .then((response) => {
                                                if (response.data.code === 586) {
                                                    setNotificationMessage('موجودی کافی نیست');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 404) {
                                                    setNotificationMessage('نمیتوانید بیشتر از 2 توکن خریداری کنید');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 1) {
                                                    setNotificationMessage('با موفقیت خریداری شد');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                        navigate('/', { replace: true });
                                                    }, 2000);
                                                } else if (response.data.code === 403) {
                                                    setNotificationMessage('توکن به اتمام رسید, دوباره وارد شوید');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        localStorage.removeItem('userData');
                                                        navigate('/login', { replace: true });
                                                    }, 3000);
                                                }
                                            })
                                            .catch((error) => {
                                                // console.error(error.response);
                                                setNotificationMessage('خطا در ارتباط با سرور. لطفا دوباره تلاش کنید.');
                                                setShowNotification(true);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className='mt-4'>
                                            <label>نام:</label>
                                            <Field className="field mt-1" type="text" name="name" value={userData.name || ''} disabled />
                                            <br />
                                            <label>ولت:</label>
                                            <Field type="text" className="field mt-1" name="wallet" placeholder="ولت" value={userData.wallet} disabled />
                                            <ErrorMessage name="wallet" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <label>تعداد توکن:</label>
                                            <Field type="number" className="field mt-1" inputMode="numeric" name="tokens" placeholder='مثلا 1' />
                                            <ErrorMessage name="tokens" component="div" className="my-2" style={{ marginRight: 8, color: 'red', fontSize: '0.8em' }} />
                                            <p className="has-text-light"> قیمت هر پک: {typeof tokenBuy === 'object' ? tokenBuy.price : tokenBuy}<strong className="ml-1 has-text-light">TRX</strong></p>
                                            <button type="submit" disabled={isSubmitting} className="btn mt-3">خرید توکن</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className="column mobile_none is-7">
                                <img src="../assets/images/lottarypic.jpg" alt="Lottery Image" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    );
}