import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import moment from 'moment-jalaali';

export default function Latary() {
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [token, setToken] = useState(null);
    const [userData, setUserData] = useState({});
    const [lotteryData, setLotteryData] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [timer, setTimer] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [currentLotteryId, setCurrentLotteryId] = useState(null); // Initialize as null
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    useEffect(() => {
        const fetchLotteryData = async () => {
            for (let id = 5; id <= 10000; id++) {
                try {
                    const response = await axios.get(`https://luckyx.cloud/api/v2/findlottery?id=${id}`);

                    if (response.data.code === 654 && response.data.error === 'Not Found') {
                        break; 
                    }

                    
                    setCurrentLotteryId(id);

                } catch (error) {
                    
                }
            }
        };

        fetchLotteryData();
    }, []);

    useEffect(() => {
        if (currentLotteryId) {
            const fetchLotteryData = async () => {
                try {
                    const response = await axios.get(`https://luckyx.cloud/api/v2/findlottery?id=${currentLotteryId}`);
                    const latestLottery = response.data;
                    setLotteryData(latestLottery);
                    setTickets(latestLottery.tickets || []);
                    const startTime = latestLottery.lottery.start_time;
                    const currentTime = Math.floor(Date.now() / 1000);
                    setTimer(currentTime - startTime);
                } catch (error) {
                }
            };

            fetchLotteryData();

            const interval = setInterval(() => {
                setTimer(prev => prev + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [currentLotteryId]);

    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="lottary__top mt-4">
                        <div className="columns lottary__top__inner">
                            <div className="column">
                                <h1 className="has-text-centered">جایزه لاتاری</h1>
                            </div>
                            <div className="column">
                                <h2 className="has-text-centered">500 TRX</h2>
                            </div>

                            <div className="column">
                                <p className="has-text-centered has-text-light" style={{ fontSize: "14px" }}>
                                    جایزه این دوره از مسابقات لاتاری
                                </p>
                            </div>

                            <div className="lottery__buttons has-text-centered">
                                {tickets.length >= 20 ? (
                                    <Link to="/latary/winners" className="button is-info mb-4">مشاهده برنده </Link>
                                ) : (
                                    <Link to="/latary/join"><button className="mb-4">شرکت</button></Link>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container px-4 mt-3 lottary__bot">
                        <table className="table is-fullwidth full-table">
                            <thead>
                                <tr className='back'>
                                    <th className='has-text-right'>نام کاربر</th>
                                    <th className='has-text-centered'>جایزه</th>
                                    <th className='has-text-centered'>تاریخ خرید</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets.length > 0 ? (
                                    Object.entries(tickets.reduce((acc, ticket) => {
                                        if (!acc[ticket.id]) {
                                            acc[ticket.id] = { ...ticket, count: 0 };
                                        }
                                        acc[ticket.id].count += 1;
                                        return acc;
                                    }, {})).map(([id, ticket]) => (
                                        <tr key={id}>
                                            <td className='has-text-right'>{ticket.name}</td>
                                            <td className='has-text-centered'>500</td>
                                            <td className='has-text-centered'>{moment.unix(ticket.date).format('jYYYY/jMM/jDD')}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className='has-text-centered'>برای شرکت بلیط خریداری کنید</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    );
}