import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import Notification from '../components/Notification';
import MobileHeader from '../components/MobileHeader';
import Botmenu from '../components/Botmenu';
import AddIcon from '@mui/icons-material/Add';

const ReferralPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });
  const [openRows, setOpenRows] = useState({});

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userDataJson = JSON.parse(storedUserData);
      setUserData(userDataJson);
      setToken(userDataJson.token);
    }
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true); // Start loading
      axios.get('https://luckyx.cloud/api/v2/user/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.data.code === 1) {
            const userData = response.data.user;
            localStorage.setItem('userData', JSON.stringify(userData));
            setUserData(userData);
            setToken(userData.token);
          } else {
            handleTokenChanged();
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // End loading
        });
    }
  }, [token]);

  const handleTokenChanged = () => {
    setIsOpen(true);
    setNotificationMessage('Token expired, Login again');
    setShowNotification(true);
    setTimeout(() => {
      localStorage.removeItem('userData');
      navigate('/login', { replace: true });
    }, 3000);
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios.get('https://luckyx.cloud/api/v2/user/myteam', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const refferalData = response.data;
          setRefferal(refferalData);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // End loading
        });
    }
  }, [token]);

  const toggleRow = (index) => {
    setOpenRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <>
      <Notification message={notificationMessage} open={showNotification} />
      <header>
        <div className="desktop_header">
          <Header />
        </div>
        <div className="mobile_header">
          <MobileHeader />
        </div>
      </header>

      <main>
        <section className='ref_section'>
          <div className="container px-4 mt-4">
            {loading ? (
              <div className="loading has-text-centered">در حال بارگذاری...</div> // نمایش لودینگ
            ) : (
              <>
                {refferal.parents1.length === 0 && refferal.parents2.length === 0 ? (
                  <div className="has-text-centered has-text-white">شما فعلاً کسی را دعوت نکرده‌اید</div>
                ) : (
                  <>
                    {refferal.parents1.map((parent, index) => (
                      <div key={index}>
                        <div onClick={() => toggleRow(index)} className='columns items'>
                          <div className="column">
                            <div className="columns is-flex">
                              <div className="column">
                                <span>{parent.name}</span>
                              </div>
                              <div className="column has-text-left">
                                <AddIcon className='iconam' />
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <div className="columns is-flex">
                              <div className="column">
                                <span className='more'>دارایی: {parent.balance}trx</span>
                              </div>
                              <div className="column">
                                <span>پاداش: {parent.reward}trx</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* نمایش parents2 زیر parent فعلی */}
                        {openRows[index] && refferal.parents2.map((subParent, subIndex) => (
                          <div key={subIndex} className='sub_refs'>
                            <div className='columns items'>
                              <div className="column">
                                <div className="columns is-flex">
                                  <div className="column">
                                    <span>{subParent.name}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="column">
                                <div className="columns is-flex">
                                  <div className="column">
                                    <span className='more'>دارایی: {subParent.balance}trx</span>
                                  </div>
                                  <div className="column">
                                    <span>پاداش: {subParent.reward}trx</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </section>


      </main>

      <footer>
        <nav className="mobile-navbar">
          <Botmenu />
        </nav>
      </footer>
    </>
  );
};

export default ReferralPage;