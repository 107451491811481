import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from 'moment-jalaali';
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";

export default function Rewards() {



    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    let [token, setToken] = useState(null); 
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    let [data, setData] = useState(false);




    useEffect(() => {
        getData();
    }, []);



    const getData = async () => {


        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);

            setToken(userDataJson.token);

            try {
                const response = await axios.get('https://luckyx.cloud/api/v2/user/box', {
                    headers: { 'Authorization': `Bearer ${userDataJson.token}` },
                });

                if (Array.isArray(response.data.box)) {
                    setData(response.data);
                } else {
                    setData([]); 
                }
            } catch (error) {
                setData([]); 
            }
        } else {
            handleLogout();
        }
    };

    const formatDate = (ymd) => {
        const year = Math.floor(ymd / 10000);
        const month = Math.floor((ymd % 10000) / 100);
        const day = ymd % 100;
    
        return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('jYYYY/jMM/jDD');
    };

    const displayedData = Array.isArray(data.box) ? data.box.slice(0, 7) : [];



    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [joinedUsersCount, setJoinedUsersCount] = useState(1);

    useEffect(() => {
        function handleUserJoined() {
            setJoinedUsersCount(prevCount => prevCount + 1);
        }
    }, []);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);
    const handleLogout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);


    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000); 
    };




    return (
        <>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main >

                <section>
                    <div className="reward-main">
                        <div className="reward-inner">
                            <div className="columns">
                                <div className="columns is-flex">
                                    <div className="column is-flex is-align-items-center">
                                        <p>پاداش من:</p>
                                    </div>
                                    <div className="column has-text-centered">
                                        <p>پاداش سطح 1</p>
                                        <div className="reward_info_top">
                                            <p>{data.parent1 ? (<>{data.parent1}</>): (<>0</>)}</p>
                                        </div>
                                    </div>
                                    <div className="column has-text-centered">
                                        <p>پاداش سطح 2</p>
                                        <div className="reward_info_top">
                                            <p>{data.parent2 ? (<>{data.parent2}</>) : (<>0</>)}</p>
                                        </div>
                                    </div>
                                    <div className="column has-text-centered">
                                        <p>کل  پاداش</p>
                                        <div className="reward_info_top">
                                            <p>{data.total ? (<>{data.total}</>): (<>0</>)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="reward_info_bot">
                            <div className="columns">
                                <div className="column">
                                    <h2 className="has-text-centered">عملکرد روزانه</h2>
                                </div>
                                <div className="column table__sec" >
                                    <table className="table is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th className="has-text-right">تاریخ</th>
                                                <th className="has-text-centered">کل پاداش</th>
                                                <th className="has-text-centered">پاداش سطح 1</th>
                                                <th className="has-text-centered">پاداش سطح 2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedData > ''  ? (
                                                <>
                                                {displayedData.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{formatDate(item.ymd)}</td>
                                                        <td className="has-text-centered">{item.total}</td>
                                                        <td className="has-text-centered">{item.parent1 }</td> 
                                                        <td className="has-text-centered">{item.parent2 }</td> 
                                                    </tr>
                                                ))}
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td>اطلاعاتی برای نمایش وجود ندارد</td>
                                                    </tr>
                                                </>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                <section>
                    <nav className="mobile-navbar">
                        <Botmenu />
                    </nav>
                </section>



            </main>
        </>
    )
}