import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";


export default function Setting() {
    const [selected, setSelected] = useState(null);

    const handleClick = (item) => {
        setSelected(item);
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});


    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');

        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);

        }
    }, []);
    const handleLogout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
        window.location.href = '/';
    };


    const [user, setUser] = useState({}); // initialize with an empty object
    const [newName, setNewName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newAvatar, setNewAvatar] = useState(null);



    const [openModal, setOpenModal] = useState(false);

    const handleUpdateProfile = () => {
        if (!newName && !newEmail && !newAvatar) {
            alert('Please fill in at least one field to update your profile.');
            return;
        }

        const formData = new FormData();
        if (newName) formData.append('name', newName);
        if (newEmail) formData.append('email', newEmail);
        if (newAvatar) formData.append('avatar', newAvatar);

        axios.post('https://luckyx.cloud/api/v2/user/editprofile', formData, {
            headers: {
                Authorization: `Bearer ${userData.token}`,
                'Content-Type': 'ultipart/form-data',
            },
        })
            .then(response => {
                setUser({
                    ...userData,
                    name: newName || userData.name,
                    email: newEmail || userData.email,
                    avatar: newAvatar instanceof File ? URL.createObjectURL(newAvatar) : userData.avatar,
                });
                setOpenModal(true);
            })
    };

    const handleCloseModal = () => {
        setOpenModal(false);

    };

    const handleAvatarChange = (e) => {
        setNewAvatar(e.target.files[0]);
    };

    const [oldPassword, setOldPassword] = useState('');

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const [passwordChanged, setPasswordChanged] = useState(false);

    const handlePasswordChange = async () => {
        if (oldPassword && newPassword) {
            try {
                const token = userData.token;
                const formData = new FormData();
                formData.append('oldpassword', oldPassword);
                formData.append('password', newPassword);

                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    },
                };

                const response = await axios.post('https://luckyx.cloud/api/v2/user/password', formData, config);

                if (response.data.code === 104) {

                    setNotificationMessage(response.data.error);
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 4000)
                } else {
                    setNotificationMessage('رمز عبور با موفقت تغییر کرد!');
                    setShowNotification(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);

                        setNewName(response.data.user.name);
                        setNewEmail(response.data.user.email);
                        setNewAvatar(response.data.user.avatar);

                    } else {
                        handleTokenChanged();
                    }
                })
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token axpired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };


    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>
            <main>
                <section>
                    <div className="profile-modal">
                        <h2 className="title has-text-centered">ویرایش پروفایل</h2>

                        <div className="profile-info mt-3">
                            <p className="label">نام کاربری:</p>
                            <input
                                type="text"
                                onChange={e => setNewName(e.target.value)}
                                placeholder="نام کاربری جدید"
                                className="input-field"
                            />
                            <p className="label">جیمیل:</p>
                            <input
                                type="email"
                                onChange={e => setNewEmail(e.target.value)}
                                placeholder="جیمیل جدید"
                                className="input-field"
                            />

                            <p className="label has-text-centered">آواتار:</p>
                            <div className=" has-text-centered">
                                <img src={userData.avatar} alt="Avatar" className="avatar" />
                            </div>
                            <input
                                type="file"
                                onChange={handleAvatarChange}
                                className="input-field"
                            />
                            <button onClick={handleUpdateProfile} className="update-btn">
                                بروزرسانی پروفایل
                            </button>
                        </div>

                        <h2 className="title has-text-centered mt-5">ویرایش رمزعبور</h2>

                        <div className="password-change mt-3">
                            <h2 className="label">تغییر رمز</h2>
                            <input
                                type="password"
                                placeholder="رمز قدیمی"
                                value={oldPassword}
                                onChange={handleOldPasswordChange}
                                className="input-field"
                            />
                            <input
                                type="password"
                                placeholder="رمز جدید"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                className="input-field"
                            />
                            <button className="update-btn" onClick={handlePasswordChange}>بروزرسانی رمز عبور</button>

                        </div>

                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: '#000',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}>
                                <Typography id="modal-title" variant="h6" component="h2">
                                    پروفایل با موفقیت بروزرسانی شد
                                </Typography>
                                <Typography id="modal-description" sx={{ mt: 2 }}>
                                    اطلاعات پروفایل شما با موفقیت بروزرسانی شد
                                </Typography>
                            </Box>
                        </Modal>
                    </div>
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    )



}