import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";
import Notification from "../components/Notification";
import moment from 'moment-jalaali'; 

export default function TokenInfo() {
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [token, setToken] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});


    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const [tickets, setTickets] = useState('');

    useEffect(() => {
        const token = userData;
        axios.get('https://luckyx.cloud/api/v2/user/mytokens', {
            headers: {
                Authorization: `Bearer ${token.token}`
            },
        })
            .then(response => {
                setTickets(response.data.mytokens);
            })
            .catch(error => {
                // console.error(error);
            });
    }, [token]);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    return (
        <>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <Notification message={notificationMessage} open={showNotification} />

                <section className="px-2">
                    <div className="container ticket_page">
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead style={{ borderBottom: '1px solid white' }}>
                                <tr>
                                    <th className="p-3">تعداد</th>
                                    <th className="p-3">ولت</th>
                                    <th className="p-3">تاریخ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets?.length > 0 ? (
                                    tickets.map(currentTicket => (
                                        <tr key={currentTicket.id}>
                                            <td className="p-3">{currentTicket.count}</td>
                                            <td className="p-3 scrollable_cell">{currentTicket.wallet}</td>
                                            <td className="p-3">
                                                {moment.unix(currentTicket.date).locale('fa').format('jYYYY/jMM/jDD')}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr style={{ borderTop: '1px solid #000' }}>
                                        <td className="p-3" colSpan={5}>تیکتی خریداری نشده است</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>

                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </main>
        </>
    )
}