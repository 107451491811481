import Header from "../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from "axios";
import LogoutIcon from '@mui/icons-material/Logout';
import TelegramIcon from '@mui/icons-material/Telegram';
import GroupsIcon from '@mui/icons-material/Groups';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import FullScreenSlider from "../components/FullScreenSlider";
import MobileHeader from "../components/MobileHeader";

export default function Home() {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(null);
    const [token, setToken] = useState(null); // Initialize token state
    const [expiresAt, setExpiresAt] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token axpired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [joinedUsersCount, setJoinedUsersCount] = useState(0);


    const handleUserJoined = () => {
        setJoinedUsersCount(prevCount => prevCount + 1);
    };

    // ...


    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    const [data, setData] = useState({});

    useEffect(() => {
        axios.get('https://luckyx.cloud/api/v1/lastlottery')
            .then(response => {
                setData(response.data);
                setJoinedUsersCount(response.data.tickets.length); // Update joinedUsersCount here
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    const wl = localStorage.getItem('userData');


    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section className="hero">
                    <FullScreenSlider />
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    )
}