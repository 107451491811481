import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Notification from "../components/Notification";
import Header from './Header';
import MobileHeader from './MobileHeader';
import Botmenu from './Botmenu';

const JoinLottary = () => {
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [token, setToken] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    return (
        <>
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>
            <main>
                <section>
                    <div className="container lotery_join_page mt-3">
                        <div className="columns join_lot">
                            <div className="column is-5">
                                <h2 className="title has-text-centered">شرکت در لاتاری</h2>
                                <p>برای شرکت در لاتاری بلیط خریداری کنید, دو بلیط میتوانید بخرید و بعد از قرعه کشی جایزه به کیف پول واریز میشود</p>
                                <Formik
                                    initialValues={{ email: userData ? userData.email : '', tickets: '' }} // Set initial email from userData or empty string
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.tickets) {
                                            errors.tickets = 'ضروری';
                                        } else if (isNaN(parseInt(values.tickets, 10)) || parseInt(values.tickets, 10) < 1 || parseInt(values.tickets, 10) > 2) {
                                            errors.tickets = 'حداکثر 2 بلیط می‌توانید خریداری کنید';
                                        }
                                        if (!values.email) {
                                            errors.email = 'ضروری';
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                            errors.email = 'ایمیل نامعتبر است';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);

                                        // Check if the entered email matches the userData email
                                        if (values.email !== userData.email) {
                                            setNotificationMessage('ایمیل وارد شده با ایمیل ذخیره شده مطابقت ندارد.');
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                            }, 3000);
                                            setSubmitting(false);
                                            return; // Stop the submission
                                        }

                                        if (userData.name === "unknown") {
                                            setNotificationMessage('لطفا نام کاربری خود را از قسمت پروفایل ویرایش کنید.');
                                            setShowNotification(true);
                                            setTimeout(() => {
                                                setShowNotification(false);
                                            }, 3000);
                                            setSubmitting(false);
                                            return; // Stop the submission
                                        }

                                        const formData = new FormData();
                                        formData.append('buy', parseInt(values.tickets, 10)); 

                                        const token = userData.token; // Get token from userData
                                        axios.post('https://luckyx.cloud/api/v2/user/buy', formData, {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        })
                                            .then((response) => {
                                                const er = response.data.error;

                                                if (response.data.code === 499) {
                                                    setNotificationMessage(er);
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 404) {
                                                    setNotificationMessage('نمیتوانید بیشتر از 2 بلیط خریداری کنید');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                    }, 3000);
                                                } else if (response.data.code === 1) {
                                                    setNotificationMessage('با موفقیت خریداری شد');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        setShowNotification(false);
                                                        navigate('/latary', { replace: true });
                                                    }, 2000);
                                                } else if (response.data.code === 403) {
                                                    setNotificationMessage('توکن به اتمام رسید, دوباره وارد شوید');
                                                    setShowNotification(true);
                                                    setTimeout(() => {
                                                        localStorage.removeItem('userData');
                                                        navigate('/login', { replace: true });
                                                    }, 3000);
                                                }
                                            })
                                            .catch((error) => {
                                                console.error(error.response); // Log the full error response
                                                setNotificationMessage(error.message);
                                                setShowNotification(true);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className='mt-4'>
                                            <label>نام:</label>
                                            <Field className="field mt-1" type="text" name="name" value={userData ? userData.name : ''} disabled />
                                            <br />
                                            <label>ایمیل:</label>
                                            <Field type="email" className="field mt-1" name="email" placeholder="ایمیل" />
                                            <ErrorMessage name="email" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <label>تعداد بلیط:</label>
                                            <Field type="number" className="field mt-1" inputMode="numeric" name="tickets" placeholder='حداکثر 2 بلیط' />
                                            <ErrorMessage name="tickets" component="div" style={{ marginRight: 8, marginTop: 5, color: 'red', fontSize: '0.8em' }} />
                                            <button type="submit" disabled={isSubmitting} className="btn mt-3">خرید بلیط</button>
                                        </Form>
                                    )}
                                </Formik>
                                <Notification message={notificationMessage} open={showNotification} />
                            </div>
                            <div className="column mobile_none is-7">
                                <img src="../assets/images/lottarypic.jpg" alt="Lottery Image" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <nav className="mobile-navbar">
                        <Botmenu />
                    </nav>
                </section>

            </main>
            <Notification message={notificationMessage} open={showNotification} />
        </>
    );
};

export default JoinLottary;