import { Link } from "react-router-dom"
import { useState, useEffect } from "react";

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export default function MobileHeader() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});



    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);


    return (
        <div className="columns is-flex is-align-items-center" style={{ width: '100%' }}>
            <div className="column is-3-mobile logo has-text-centered px-3 py-0 pt-3" >
                <img src="../assets/images/logo2.png" alt="" style={{ width: '100%', paddingRight: '20px' }} />
            </div>
            {isLoggedIn ? (
                <>
                    <div className="column is-6-mobile is-flex mobile__header_wallet">
                        <Link to={'/wallet'}>
                            <span className="mobile__header_wallet_info1">
                                <AccountBalanceWalletIcon className="iconm" />
                            </span>
                        </Link>
                        <div className="mobile__header_wallet_info_inner">
                            <p>{userData.balance}</p>
                            <img src="../assets/icon/trx.png" alt="" />
                        </div>
                    </div>
                    <div className="column has-text-left ml-3 is-flex mobile__header_wallet_info2" >
                        <p className="ml-2">{userData.name}</p>
                        <img src={userData.avatar} alt="pic" />
                    </div>
                </>
            ) : (
                <div className="column" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <div className="buttons pl-4" style={{ width: '230px' }}>
                        <div className="buttonn ml-2">
                            <Link to={'/sign-up'} className="btnn fx01">
                                <span>ثبت نام</span>
                            </Link>
                        </div>
                        <div className="buttonn ">
                            <Link href="#" to={'/Login'} className="btnn fx02">
                                <span>ورود</span>
                            </Link>
                        </div>
                    </div>
                </div>
            )
            }

        </div >
    )
}