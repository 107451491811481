

import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import HouseIcon from '@mui/icons-material/House';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import axios from 'axios';
import Notification from "./Notification";
import Botmenu from "./Botmenu";
import MobileHeader from "./MobileHeader";

export default function Withdraw() {
    const [selected, setSelected] = useState(null);
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // create a state variable to track the submission status
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [token, setToken] = useState(null); // Initialize token state
    const [expiresAt, setExpiresAt] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [userData, setUserData] = useState({});

    const handleClick = (item) => {
        setSelected(item);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };


    const handleSubmit = async (e) => {
        setIsSubmitting(true);
        e.preventDefault();
        try {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const token = userData.token;
            const formData = new FormData();
            formData.append('address', userData.wallet);
            formData.append('amount', amount);
            axios.post('https://luckyx.cloud/api/v2/user/withdraw', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    setIsOpen(true);
                    if(response.data.code === 498){
                        setNotificationMessage("مشکل در سرور بلاکچین بوجود آمده است");
                    } 
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 4000);
                })
                .catch(error => {
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } catch (error) {
            // console.error(error);
            // Handle error response
        }
    };


    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token axpired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <div className="container withdraw_p">
                    <div className="inner">
                        <label>آدرس کیف پول:</label>
                        <input className="input" type="text" disabled value={userData.wallet} />

                        <br />

                        <label>مقدار:</label>
                        <input className="input" type="number" placeholder="حداقل 30trx" value={amount} onChange={handleAmountChange} />

                        <p className="mt-3">کارمزد 0trx</p>

                        <div className=" columns has-text-centered mt-5">
                            <div className="column" style={{width: '100%'}}>
                                <button
                                    className="btn2"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <span>
                                            <i className="fas fa-spinner fa-spin" /> بارگذاری برداشت...
                                        </span>
                                    ) : (
                                        'برداشت'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>




                <nav className="mobile-navbar">
                    <Botmenu />

                </nav>
            </main>

            <footer>

            </footer>
        </>
    )
}