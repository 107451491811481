import Header from "../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import BarChartIcon from '@mui/icons-material/BarChart';
import Notification from "../components/Notification";
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";
import { QRCodeCanvas } from 'qrcode.react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import StarsIcon from '@mui/icons-material/Stars';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export default function Profile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [selected, setSelected] = useState(null);
    const [token, setToken] = useState(null); // Initialize token state
    const [expiresAt, setExpiresAt] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const UserToken = userData.token
    const [inviteLink, setInviteLink] = useState('');




    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userDataJson = JSON.parse(storedUserData);
            setUserData(userDataJson);
            setToken(userDataJson.token);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        setInviteLink(`https://luckyx.cloud/sign-up?referral=${userData.referral}`);
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                        setTotalReward(response.data.user.reward)
                    } else {

                        handleTokenChanged();
                    }
                })
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token axpired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    const [parents, setParents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalReward, setTotalReward] = useState(0);
    const [box, setBox] = useState([])





    const [refferal, setRefferal] = useState({ parents1: [], parents2: [] });

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/myteam', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    const refferalData = response.data;
                    setRefferal(refferalData);

                })

        }
    }, [token]);





    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        axios.get('https://luckyx.cloud/api/v2/user/mytickets', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setTickets(response.data.tickets);
            })
    }, [token]);

    if (!userData) {
        return null;
    }



    const handleShare = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setNotificationMessage('لینک شما کپی شد!');
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);
            })
    };



    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="profile_bod">
                        <div className="profile__head">
                            {/* <div className="columns is-flex">
                                <div className="column is-9-mobile has-text-left">
                                    <h3>{userData.name}</h3>
                                </div>
                                <div className="column is-3-mobile has-text-left">
                                    <img src={userData.avatar} alt="avatar" style={{width:40 , height: 40, maxWidth: 40}}/>

                                </div>
                            </div> */}
                        </div>
                        <div className="link__section mt-4">
                            <div className="columns is-flex">
                                <div className="column is-6-mobile is-flex is-justify-content-center is-align-items-center">
                                    <button onClick={handleShare}>
                                        اشتراک گذاری
                                    </button>

                                </div>
                                <div className="column is-flex is-justify-content-center">
                                    <QRCodeCanvas value={inviteLink} size={128} />
                                </div>
                            </div>
                        </div>

                        <div className="winners mt-4">
                            <div className="columns is-flex">
                                <div className="column is-align-items-center is-flex ">
                                    <img src="../assets/icon/giftt.png" alt="" />
                                    <p className="mr-3">برندگان</p>
                                </div>
                                <div className="column is-justify-content-flex-end is-align-items-center is-flex ">
                                    <Link to={'/latary/winners'}>
                                        <button>
                                            مشاهده همه
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="status mt-4">
                            <div className="columns">
                                <div className="column is-flex  is-align-items-center">
                                    <BarChartIcon className="iconm" />
                                    <p className="mr-2 mt-1">آمار من</p>
                                </div>
                                <div className="column is-flex is-justify-content-space-between">
                                    <div className="info">
                                        <p className="has-text-centered">نفرات سطح 1</p>
                                        <div className="has-text-centered">
                                            {refferal.parents1?.length > 0 ? refferal.parents1.length : 0}
                                        </div>
                                    </div>
                                    <div className="info mx-2">
                                        <p className="has-text-centered">نفرات سطح 2</p>
                                        <div className="has-text-centered">
                                            {refferal.parents2?.length > 0 ? refferal.parents2.length : 0}
                                        </div>
                                    </div>
                                    <div className="info">
                                        <p className="has-text-centered"> پاداش دریافتی</p>
                                        <div className="has-text-centered">{userData.reward > 0 ? (<>{totalReward}</>) : (<>0</>)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info_menu mt-5">
                            <div className="info_menu_inner">
                                <Link to={'/Wallet'}>
                                    <div className=" info_menu_inner_menus is-flex" >

                                        <AccountBalanceWalletIcon className="iconmn" />
                                        <h2>کیف پول شما</h2>

                                    </div>
                                </Link>
                                <Link to={'/profile/tickets'}>
                                    <div className=" info_menu_inner_menus  is-flex">
                                        <LocalActivityIcon className="iconmn" />
                                        <h2>بلیط های من</h2>

                                    </div>
                                </Link>
                                <Link to={'/token'}>
                                    <div className="info_menu_inner_menus  is-flex">
                                        <ConfirmationNumberIcon className="iconmn" />
                                        <h2>توکن های من </h2>
                                    </div>
                                    {/* <strong className="has-text-dangered"> (به زودی) </strong> */}
                                </Link>
                                <Link to={'/latary/winners'}>
                                    <div className="info_menu_inner_menus  is-flex">
                                        <EmojiEventsIcon className="iconmn" />
                                        <h2>برنده های جوایز </h2>
                                    </div>
                                </Link>


                                <Link to={'/Verify'}>
                                    <div className=" info_menu_inner_menus is-flex" >
                                        <VerifiedIcon className="iconmn" />
                                        <h2>تایید آدرس برداشت پاداش</h2>

                                    </div>
                                </Link>
                                <Link to={'/referral-code'}>
                                    <div className=" info_menu_inner_menus  is-flex">
                                        <SupervisorAccountIcon className="iconmn" />
                                        <h2>دعوت از دوستان</h2>
                                    </div>
                                </Link>
                                <Link to={'/profile/rewards'}>
                                    <div className=" info_menu_inner_menus  is-flex">
                                        <StarsIcon className="iconmn" />
                                        <h2>پاداش شما</h2>
                                    </div>
                                </Link>
                                <Link to={'/ref'}>
                                    <div className="info_menu_inner_menus  is-flex">
                                        <GroupsIcon className="iconmn" />
                                        <h2>دوستان شما</h2>
                                    </div>
                                </Link>
                                <Link to={'/setting'}>
                                    <div className=" info_menu_inner_menus  is-flex">
                                        <ChangeCircleIcon className="iconmn" />
                                        <h2>ویرایش پروفایل</h2>
                                    </div>
                                </Link>
                                <hr style={{ width: '100em', position: 'relative', left: '2em', marginBottom: '7em' }} />


                            </div>

                        </div>
                    </div>
                </section>

                {/* <div className="wallet">
                    <div className="top_wallet ">
                        <div className="top_wallet_inner container px-5">
                            <h3>welcome Back!</h3>
                            <div className="columns">
                                <div className="column">
                                    <div className="wallet_top_image">
                                        <img src={userData.avatar} alt="" />
                                    </div>
                                </div>
                                <div className="column is-flex is-justify-content-center top_inf">
                                    <span className="has-text-left">
                                        <h4 className="has-text-light">{userData.name}</h4>
                                        <h2 style={{ color: 'yellow' }}>{userData.balance}</h2>
                                        <p className="has-text-light">Available Balance</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns container is-justify-content-space-between is-flex-mobile bottom_wallet px-3">
                        <div className="column is-6-mobile">
                            <h2>Activities</h2>
                            <div>
                                <Link to={'/profile/rewards'}>
                                    <div className="info_pending ">
                                        <span className="detailss" >Details</span>
                                        <div className="columns is-flex-mobile has-text-centered">
                                            <div className="column">
                                                <p style={{ fontSize: 14, fontWeight: 'bold' }}> rewards <br /><strong style={{ fontSize: 40, color: 'yellow' }}>{totalReward} </strong></p>
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/profile/tickets'}>
                                    <div className="info_pending mt-2 ">
                                        <span className="detailss" >Details</span>
                                        <div className="has-text-centered tickets">
                                            <h3>Tickets Buy</h3>
                                            {tickets && <h2>{tickets?.length ?? 0}</h2>}
                                        </div>
                                    </div>

                                </Link>
                            </div>
                        </div>

                        <div className="column  is-6-mobile">
                            <Link to={'/allRef'}>
                                <h2 className="has-text-centered">Reports Of Ref</h2>
                                <div className="referalls">
                                    <h2>{parents.countchilds}</h2>
                                    <p>Referalls</p>
                                    <p className="mt-4">
                                        referalls have
                                    </p>
                                    <span className="is-flex mt-5">
                                        {subLeftAvatar && <img src={`${process.env.PUBLIC_URL}/${subLeftAvatar}`} alt={'image'} />}
                                        {subRightAvatar && <img src={`${process.env.PUBLIC_URL}/${subRightAvatar}`} alt={'image'} />}
                                        <div className="info_image">
                                            <p className="has-text-light">+{(subLeftName ? 1 : 0) + (subRightName ? 1 : 0)}</p>
                                        </div>
                                    </span>
                                </div>


                            </Link>
                        </div>
                    </div>

                </div> */}

            </main >

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    )
}