import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Botmenu from "../components/Botmenu";
import MobileHeader from "../components/MobileHeader";
import Notification from "../components/Notification";

export default function Menu() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});

    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);



    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
    };

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                })
        }
    }, [token]);
    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token axpired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <main className="menu_page ">
                <section className="info_menu">
                    <div className="info_menu_inner has-text-centered">
                        <h1 className="pt-5 has-text-light ">منو</h1>
                        <div className="columns mt-5 ">
                            <div className="column menu_head">
                                {isLoggedIn ? (
                                    <img src={userData.avatar} width={100} alt="user" style={{ maxWidth: '80%', marginRight: 10 }} />
                                ) : (
                                    <AccountCircleIcon className="menu_icon" />
                                )}
                                <h3>به لاکی ایکس خوش آمدید</h3>
                                {isLoggedIn ? (<p>برای شرکت در لاتاری بلیط خریداری کنید</p>) : (<p className="mt-3">برای استفاده از خدمات ثبت نام کنید و یا وارد شوید</p>)}

                            </div>

                        </div>
                        <div className="buttons columns" >
                            {isLoggedIn ? (
                                <>
                                    <div className="logout-icon1 column is-12-mobile">
                                        <button onClick={handleLogout} className="btnn has-text-centered" style={{ width: '100%', maxWidth: '100%', letterSpacing: '0' }} >
                                            خروج از حساب
                                        </button>

                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="buttonn column">
                                        <Link to={'/sign-up'} className="btnn fx01">
                                            <span>ثبت نام</span>
                                        </Link>
                                    </div>
                                    <div className="buttonn column">
                                        <Link href="#" to={'/Login'} className="btnn fx02">
                                            <span>ورود</span>
                                        </Link>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </section>

                <section className="info_menu2">
                    {isLoggedIn ? (
                        <>
                            <div className="culomns items  px-4">
                                <Link to={'/'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/home.png" alt="icon" />
                                        <p className="has-text-light">خانه</p>
                                    </div>
                                </Link>
                                <Link to={'/latary/join'}>
                                    <div className="culomn item  has-text-centered is-4-mobile">
                                        <img src="../assets/icon/tik.png" alt="icon" />
                                        <p className="has-text-light">خریدن بلیط</p>
                                    </div>
                                </Link>
                                <Link to={'/referral-code'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/reflink.png" alt="icon" />
                                        <p className="has-text-light">لینک دعوت من</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="culomns items mt-3  px-4">
                                <Link to={'/profile/rewards'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/salary.png" alt="icon" />
                                        <p className="has-text-light">پاداش من</p>
                                    </div>
                                </Link>
                                <Link to={'/ref'}>
                                    <div className="culomn item  has-text-centered is-4-mobile">
                                        <img src="../assets/icon/team.png" alt="icon" />
                                        <p className="has-text-light">مجموعه من</p>
                                    </div>
                                </Link>
                                <Link to={'/profile/tickets'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/buyticket.png" alt="icon" />
                                        <p className="has-text-light">بلیط های من</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="culomns items mt-3  px-4">
                                <Link to={'/wallet/deposit'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/import.png" alt="icon" />
                                        <p className="has-text-light">واریز</p>
                                    </div>
                                </Link>
                                <Link to={'/wallet/withdraw'}>
                                    <div className="culomn item  has-text-centered is-4-mobile">
                                        <img src="../assets/icon/cashout.png" alt="icon" />
                                        <p className="has-text-light">برداشت</p>
                                    </div>
                                </Link>
                                <Link to={'/wallet'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/transaction.png" alt="icon" />
                                        <p className="has-text-light">تراکنش ها</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="culomns items mt-3  px-4" style={{ marginBottom: '15vh' }} >
                                <Link to={'/latary/winners'}>
                                    <div className="culomn item has-text-centered is-4-mobile" >
                                        <img src="../assets/icon/cup.png" alt="icon" />
                                        <p className="has-text-light">برنده ها</p>
                                    </div>
                                </Link>
                                <Link to={'#'}>
                                    <div className="culomn item has-text-centered is-4-mobile " style={{ opacity: .5, cursor: 'no-drop' }}>
                                        <img src="../assets/icon/help.png" alt="icon" />
                                        <p className="has-text-light"> معرف برندگان</p>
                                    </div>
                                </Link>

                                <Link to={'/token/buy'}>
                                    <div className="culomn item has-text-centered is-4-mobile " >
                                        <img src="../assets/icon/ticket2.png" alt="icon" />
                                        <p className="has-text-light"> خریدن توکن </p>
                                    </div>
                                </Link>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="culomns items  px-4">
                                <Link to={'/'}>
                                    <div className="culomn item has-text-centered is-4-mobile">
                                        <img src="../assets/icon/home.png" alt="icon" />
                                        <p className="has-text-light">خانه</p>
                                    </div>
                                </Link>
                                <Link to={'/latary'}>
                                    <div className="culomn item  has-text-centered is-4-mobile">
                                        <img src="../assets/icon/joiners.png" alt="icon" />
                                        <p className="has-text-light">شرکت کنندگان</p>
                                    </div>
                                </Link>
                                <Link to={'/latary/winners'}>
                                    <div className="culomn item has-text-centered is-4-mobile" >
                                        <img src="../assets/icon/cup.png" alt="icon" />
                                        <p className="has-text-light">برنده ها</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="culomns items mt-3  px-4">
                                <Link to={'#'}>
                                    <div className="culomn item has-text-centered is-4-mobile" style={{ opacity: .5 }}>
                                        <img src="../assets/icon/gift.png" alt="icon" />
                                        <p className="has-text-light"> طرح درآمدزایی</p>
                                    </div>
                                </Link>

                                <Link to={'/token/buy'}>
                                    <div className="culomn item has-text-centered is-4-mobile " >
                                        <img src="../assets/icon/ticket2.png" alt="icon" />
                                        <p className="has-text-light"> خریدن توکن </p>
                                    </div>
                                </Link>
                                <Link to={'#'}>
                                    <div className="culomn item has-text-centered is-4-mobile" style={{ opacity: .5 }}>
                                        <img src="../assets/icon/help.png" alt="icon" />
                                        <p className="has-text-light"> معرف برندگان</p>
                                    </div>
                                </Link>
                            </div>


                        </>
                    )}
                </section>
            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    )
}