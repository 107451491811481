import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment-jalaali';
import Notification from "../components/Notification";
import MobileHeader from "../components/MobileHeader";
import Botmenu from "../components/Botmenu";

export default function Winners() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [lotteryData, setLotteryData] = useState(null);
    const [winners, setWinnersData] = useState([]);
    const [showModal, setShowModal] = useState(false); // State for modal visibility

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.get('https://luckyx.cloud/api/v2/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data.code === 1) {
                        const userData = response.data.user;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        setUserData(userData);
                        setToken(userData.token);
                    } else {
                        handleTokenChanged();
                    }
                });
        }
    }, [token]);

    const handleTokenChanged = () => {
        setIsOpen(true);
        setNotificationMessage('Token expired, Login again');
        setShowNotification(true);
        setTimeout(() => {
            localStorage.removeItem('userData');
            navigate('/login', { replace: true });
        }, 3000);
    };

    // Fetch winners data
    useEffect(() => {
        const fetchWinnerData = async () => {
            try {
                const response = await axios.get('https://luckyx.cloud/api/v2/winners');
                if (response.data.code === 1) {
                    setWinnersData(response.data.wiiners || []); // Assuming the winners data is in response.data.wiiners
                } else {
                    setWinnersData([]);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchWinnerData();
    }, []);

    // Fetch lottery data
    useEffect(() => {
        const fetchLotteryData = async () => {
            try {
                const response = await axios.get('https://luckyx.cloud/api/v2/lastlottery');
                setLotteryData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchLotteryData();
    }, []);

    if (!lotteryData) {
        return <div>Loading...</div>;
    }

    const { lottery, tickets } = lotteryData;

    const formatDate = (timestamp) => {
        if (!timestamp) return 'نامعلوم';
        return moment.unix(timestamp).format('jYYYY/jMM/jDD');
    };

    const startTime = formatDate(lottery.start_time);
    const endTime = formatDate(lottery.end_time);
    const winner = lottery.winner !== null ? lottery.winner : 'نامعلوم';
    const ticketCount = tickets.length;

    return (
        <>
            <Notification message={notificationMessage} open={showNotification} />
            <header>
                <div className="desktop_header">
                    <Header />
                </div>
                <div className="mobile_header">
                    <MobileHeader />
                </div>
            </header>

            <main>
                <section>
                    <div className="container winner__page">
                        <h1 className="title">اطلاعات مسابقه قبلی</h1>
                        <div className="columns">
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">تاریخ شروع:</h2>
                                    <p>{startTime}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">تاریخ اتمام:</h2>
                                    <p>{endTime}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">برنده:</h2>
                                    <p>{winner}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <h2 className="subtitle">تعداد بلیط های خریداری شده :</h2>
                                    <p>{ticketCount}</p>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column has-text-centered">
                                <button className="button is-info " onClick={() => setShowModal(true)}>
                                    مشاهده تمام برندگان
                                </button>
                            </div>
                        </div>

                        {showModal && (
                            <div className="modal is-active">
                                <div className="modal-background" onClick={() => setShowModal(false)}></div>
                                <div className="modal-content">
                                    <div className="box">
                                        <h2 className="title has-text-centered">تمام برندگان</h2>
                                        <table className="table is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th className="has-text-centered">نام</th>
                                                    <th className="has-text-centered">ایمیل</th>
                                                    <th className="has-text-centered">تاریخ</th>
                                                    <th className="has-text-centered">معرف</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {winners.map((winner, index) => (
                                                    <tr key={index}>
                                                        <td>{winner.name}</td>
                                                        <td>{winner.email}</td>
                                                        <td>{winner.date}</td>
                                                        <td>
                                                            {winner.has_parent ? (
                                                                `${winner.referrer_name} (${winner.referrer_email})` // Show referrer name and email
                                                            ) : (
                                                                ' ندارد' 
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button className="button" onClick={() => setShowModal(false)}>بستن</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>


            </main>

            <footer>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </footer>
        </>
    );
}