import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Botmenu() {

    const [selected, setSelected] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});



    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
    }, []);

    const handleClick = (item) => {
        setSelected(item);
    };



    return (
        <ul>
            <li className="has-text-centered">
                <Link to="/menu" className={selected === 'Profile' ? 'selected' : ''} onClick={() => handleClick('Wallet')}>
                    <img src="../assets/icon/menu.png" width={40} className="mobileMenu_icons" />
                    <p>منو</p>
                </Link>
            </li>
            {isLoggedIn ? (
                <li className="has-text-centered">
                    <Link to="/Profile" className={selected === 'Profile' ? 'selected' : ''} onClick={() => handleClick('Profile')}>
                        <img src="../assets/icon/profile.png" width={35} className="mobileMenu_icons" />
                        <p>پروفایل</p>
                    </Link>
                </li>
            ) : (
                <li className="has-text-centered">
                    <Link to={"/login"} className={selected === 'home' ? 'selected' : ''} onClick={() => handleClick('home')}>
                        <img src="../assets/icon/login.png" width={33} className="mobileMenu_icons" />
                        <p>ورود</p>
                    </Link>
                </li>
            )}
            
            <li className="has-text-centered">
                <Link to="/wallet" className={selected === 'lotarry' ? 'selected' : ''} onClick={() => handleClick('lottery')}>
                    <img src="../assets/icon/wallett.png" width={38} className="mobileMenu_icons" />
                    <p>کیف پول</p>
                </Link>
            </li>

            <li className="has-text-centered">
                <Link to="/latary" className={selected === 'lotarry' ? 'selected' : ''} onClick={() => handleClick('lottery')}>
                    <img src="../assets/icon/ticket.svg" width={38} className="mobileMenu_icons" />
                    <p>لاتاری</p>
                </Link>
            </li>



        </ul>
    )
}