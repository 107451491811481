import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from "react-router-dom";
import axios from 'axios';
import Notification from '../components/Notification';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Botmenu from "../components/Botmenu";
import GroupIcon from '@mui/icons-material/Group';

const SIgnUp = () => {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(true);
    const [showSignUp, setShowSignUp] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const toggleDropdown = () => {
        setIsOpenMenu(!isOpenMenu);
    };


    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsButtonDisabled(false);
        setTimer(null);
    };

    const handleSendEmail = () => {

        setIsButtonDisabled(true);
        setTimer(60);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalId);
            setIsButtonDisabled(false);
            setTimer(null);
        }, 60000);
    };


    const handleLoginClick = () => {
        setShowLogin(true);
        setShowSignUp(false);
        document.querySelector('.login__bod2').classList.add('show');
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [error, setError] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // State for checkbox
    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        // خواندن کد رفرال از URL
        const params = new URLSearchParams(window.location.search);
        const referralFromUrl = params.get('referral');

        if (referralFromUrl) {
            setReferralCode(referralFromUrl); // اگر کد دعوت وجود دارد، آن را تنظیم کن
            setIsReadOnly(true); // ورودی را غیرقابل ویرایش کن
        } else {
            setIsReadOnly(false); // ورودی قابل ویرایش است
        }
    }, []);

    const Signupp = (e) => {

        e.preventDefault(); // Prevent default form submission


        if (!email || !password) {
            setNotificationMessage('لطفا تمام فیلد هارا کامل کنید');
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000); // Close the notification after 3 seconds
            return;
        }

        if (!isChecked) {
            setError('شما باید قوانین و مقررات را بپذیرید.');
            return;
        }

        if (password !== confirmPassword) {
            alert("رمز عبور و تکرار آن باید برابر باشند.");
            return;
        }

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('referral', referralCode);
        formData.append('name', 'unknown');
        formData.append('wallet', 'unknown');

        axios.post('https://luckyx.cloud/api/v2/register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const code = response.data.code;
                setError(code); // store the code in the error state variable
                if (code === 1 || code === 1) {
                    setNotificationMessage('با موفقیت اکانت ساخته شد لطفا وارد شوید.');
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                    axios.post('https://luckyx.cloud/api/v2/login', {
                        email,
                        password,
                    })
                        .then(response => {
                            const userData = response.data;
                            localStorage.setItem('UserData', JSON.stringify(userData));
                            window.location.href = '/profile';
                        })

                } else if (response.data.error === 144) {
                    setNotificationMessage('کد دعوت نامعتبر است')
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 3000);
                } else if (response.data.error === 140) {
                    setNotificationMessage('این یوزر از قبل موجود است')
                    setShowNotification(true)
                    setTimeout(() => {
                        setShowNotification(false)
                    }, 3000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const [hideReferralCodeInput, setHideReferralCodeInput] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCodeFromUrl = urlParams.get('referralCode');
        if (referralCodeFromUrl) {
            setReferralCode(referralCodeFromUrl);
            setHideReferralCodeInput(true);
        }
    }, []);




    return (
        <div className="signup">
            <Notification message={notificationMessage} open={showNotification} />
            <section>
                <div className="menu__top__login columns is-flex is-justify-content-space-between mt-1 px-1">
                    <button onClick={toggleDropdown} className="dropdown-button">
                        {isOpenMenu ? 'بستن' : 'منوی آموزش'}
                    </button>

                    {isOpenMenu && (
                        <div className="show_menu">
                            <div className="column is-12-mobile ">
                                <Link to="/educational-videos">فیلم آموزشی</Link>
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/rewards">جوایز</Link>
                            </div>
                            <div className="column is-12-mobile ">
                                <Link to="/support">پشتیبانی</Link>
                            </div>
                        </div>
                    )}
                </div>
            </section >
            <section className='signup__bod'>
                <div className="google_telegram has-text-centered is-flex">
                    <Link to={'#'} style={{ marginLeft: '5px' }}>
                        <button >
                            <img src="../assets/icon/google.png" width={20} alt="" className="ml-2" />
                            <p>گوگل</p>
                        </button>
                    </Link>
                    <Link to={"https://t.me/TrainingLuckyCchanel"}>
                        <button>
                            <img src="../assets/icon/telegram.png" width={20} className="ml-2" alt="" />
                            <p>تلگرام</p>
                        </button>
                    </Link>
                </div>
                <div className="text has-text-centered mt-4">
                    <span class="line"></span>
                    یا وارد شوید از طریق
                    <span class="line"></span>
                </div>

                <div className="login-form mt-6 px-2">
                    <div className="input-container columns">
                        <div className="column lab is-2 is-2-mobile">
                            <EmailIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">آدرس ایمیل</label>
                            <input
                                className="input-field"
                                type="text"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder="ایمیل خود را وارد کنید"
                            />
                        </div>
                    </div>

                    <div className="input-container columns">
                        <div className="column is-2 is-2-mobile lab">
                            <VpnKeyIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">رمز عبور</label>
                            <input
                                className="input-field"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="رمز عبور خود را وارد کنید"
                            />
                        </div>
                        <div className="column is-2 is-2-mobile lab" onClick={togglePasswordVisibility}>
                            <RemoveRedEyeIcon className="iconm" style={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                    <div className="input-container columns">
                        <div className="column is-2 is-2-mobile lab">
                            <VpnKeyIcon className="iconm" />
                        </div>
                        <div className="column">
                            <label className="input-label">تکرار رمز عبور</label>
                            <input
                                className="input-field"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                placeholder="تکرار رمز عبور خود را وارد کنید"
                            />
                        </div>
                        <div className="column is-2 is-2-mobile lab" onClick={togglePasswordVisibility}>
                            <RemoveRedEyeIcon className="iconm" style={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                    <div className="input-container columns">

                        {!hideReferralCodeInput && (
                            <>
                                <div className="column is-2 is-2-mobile lab">
                                    <GroupIcon className="iconm" />
                                </div>
                                <div className="column">
                                    <label className="input-label">کد دعوت</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={referralCode}
                                        placeholder='کد دعوت را وارد کنید'
                                        onChange={e => setReferralCode(e.target.value)}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                            </>
                        )}
                        {hideReferralCodeInput && (
                            <input
                                className="input-field"
                                type="hidden"
                                value={referralCode}
                            />
                        )}
                    </div>

                    <div className=" columns">
                        <div className="column is-flex">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={isChecked}
                                className="custom-checkbox"
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <label className="input-labell mr-3">من قوانین را میپذیرم که 18 سال سن دارم</label>
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>

                    <div className={`modal ${isOpen ? "is-active" : ""}`}>
                        <div className="modal-background" onClick={handleCloseModal} />
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title is-size-6 has-text-light">برای دریافت پسوورد پیام دهید</p>
                                <button
                                    className="delete"
                                    aria-label="close"
                                    onClick={handleCloseModal}
                                    style={{ padding: 10 }}
                                />
                            </header>
                            <section className="modal-card-body">
                                <a href="https://t.me/ownerluckyX" style={{ color: '#000' }}>
                                    <button
                                        onClick={handleSendEmail}
                                        className="button is-primary"
                                        disabled={isButtonDisabled}
                                        style={{ display: 'block', position: 'relative', top: 0, left: 0, padding: 20 }}
                                    >
                                        ارتباط با ادمین
                                    </button>
                                </a>
                            </section>
                        </div>
                    </div>

                    <div className="is-flex is-justify-content-space-between">
                        <button
                            className="mb-4"
                            onClick={Signupp}
                            style={{ backgroundColor: "#2C2F33" }}
                        >
                            ثبت نام
                        </button>
                    </div>

                    <div className="mt-3 has-text-centered">
                        <p className="login__to__signUp">
                            حساب کاربری دارید؟
                            <Link to={"/Login"} className="mr-2">
                                ورود
                            </Link>
                        </p>
                    </div>
                </div>
            </section>


            <section>
                <nav className="mobile-navbar">
                    <Botmenu />
                </nav>
            </section>


            {/* <div>
                <div className='has-text-centered' style={{ color: 'red' }}>{error}</div>
                <div className="SignUp mt-6">
                    <div className="input-container">
                        <label className="input-label">نام</label>
                        <input
                            className="input-field"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>

                    <div className="input-container">
                        <label className="input-label">ایمیل</label>
                        <input
                            className="input-field"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="input-container">
                        <label className="input-label">پسوورد</label>
                        <input
                            className="input-field"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="input-container">
                        <label className="input-label">کیف پول</label>
                        <input
                            className="input-field"
                            type="text"
                            value={wallet}
                            onChange={e => setWallet(e.target.value)}
                        />
                    </div>

                    <div className="input-container">

                        {!hideReferralCodeInput && (
                            <>
                                <label className="input-label">کد دعوت</label>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={referralCode}
                                    onChange={e => setReferralCode(e.target.value)}
                                />
                            </>
                        )}
                        {hideReferralCodeInput && (
                            <input
                                className="input-field"
                                type="hidden"
                                value={referralCode}
                            />
                        )}
                    </div>
                </div>

                <div className="columns is-flex is-justify-content-space-between">
                    <div className="column is-6-mobile  ">
                        <button onClick={Signupp} style={{ backgroundColor: 'var(--secondary-button-color)' }}>ثبت نام</button>

                    </div>
                    <div className="column is-6-mobile ">
                        <button onClick={handleSignUpClick} style={{ backgroundColor: 'var(--primary-button-color)', color: 'var(--white-color)' }}>ورود</button>
                    </div>
                </div>
            </div> */}


        </div>

    );
};

export default SIgnUp;